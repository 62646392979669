import React from "react";
import { PageWrapper } from "~components/Core";
import BreadCrumbSection from "~sections/about/BreadCrumb";


export default function About() {
  return (
    <PageWrapper innerPage={true}>
        <BreadCrumbSection title="About" text="Our focus is always on finding the best people to work with. Our <br class='d-none d-md-block'/>
bar is high, but you look ready to take on the challenge."/>
        
    </PageWrapper>
  )
}
